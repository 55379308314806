import { validationInvestmentProperty, actions } from './financial'

const schema = [
  {
    component: 'div',
    class: 'tw-mt-4 tw-mb-8 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        type: 'checkbox',
        name: 'is_investment_property',
        label: 'Investeringsvastgoed',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        type: 'number',
        name: 'annual_rent',
        label: 'Jaarhuur (effectieve/verwachte huurprijs)',
        placeholder: 'Jaarhuur',
        min: 0,
        validation: 'bail|validationInvestmentProperty|number|min:0',
        validationRules: { validationInvestmentProperty },
        validationMessages: {
          validationInvestmentProperty: 'Jaarhuur is verplicht voor een investeringspand'
        },
        outerClass: 'tw-m-0'
      }
    ]
  }
]

export default {
  heading: 'Investeringsvastgoed (te huur)',
  schema,
  actions
}
